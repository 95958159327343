import { React, useState } from "react";
import "./Portfolio.css";
import { Container, Col, Row } from "react-bootstrap";
import image1 from "../../assets/shtrime_parketi1.jpg";
import image2 from "../../assets/shtrime_parketi2.jpg";
import image3 from "../../assets/shtrime_parketi3.jpg";

import image4 from "../../assets/punime_gipsi1.jpg"
import image5 from "../../assets/punime_gipsi2.jpg"
import image6 from "../../assets/punime_gipsi3.jpg"

import image7 from "../../assets/punime_pllakash1.jpg"
import image8 from "../../assets/punime_pllakash2.jpg"
import image9 from "../../assets/punime_pllakash3.jpg"
import image10 from "../../assets/punime_pllakash4.jpg"
import image11 from "../../assets/punime_pllakash5.jpg"
import image16 from "../../assets/punime_pllakash6.jpg"
import image17 from "../../assets/punime_pllakash7.jpg"


import image12 from "../../assets/suvatime1.jpg"
import image13 from "../../assets/suvatime2.jpg"
import image14 from "../../assets/suvatime3.jpg"
import image15 from "../../assets/suvatime4.jpg"
import image18 from "../../assets/white1.jpg"
import image19 from "../../assets/white2.jpg"
import image20 from "../../assets/white3.jpg"

import image21 from "../../assets/lyerje1.jpg"
import image22 from "../../assets/lyerje2.jpg"
import image23 from "../../assets/lyerje3.jpg"
import image24 from "../../assets/lyerje4.jpg"


import { Card } from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Portfolio = () => {
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const stylingObject = {
    button: {
      backgroundColor: '#567189',
      padding: '10px 12px',
      border: 'none',
      fontWeight: '600',
      textTransform: 'uppercase',
      fontSize: '14px',
      marginRight: '7%',
      marginLeft: '5%'

    }
  }

  const styleObject = {
    button: {
      backgroundColor: 'white',
      padding: '10px 12px',
      border: 'none',
      fontWeight: '600',
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
      marginRight: '7%',
      marginLeft: '5%'

    }
  }

const modal = document.getElementsByClassName('modal');
const img = document.getElementsByClassName('portfolio-image');
const modalImg = document.getElementsByClassName("img01");

const showModal = ()=>{
  modal[0].style.display = "block";
  modalImg[0].src = img[0].src;
}


// When the user clicks on <span> (x), close the modal
// modal.onclick = function() {
//   modalImg.className += " out";
//     setTimeout(function() {
//        modal.style.display = "none";
//        modalImg.className = "modal-content";
//      }, 400);
    
//  }   



  const items1 = [
    <Card className="card-portfolio">
      <img src={image1} className="portfolio-image" onClick={showModal}/>
          <div id="myModal" className="modal">
              <img class="modal-content" className="img01"/>
              <div id="caption"></div>
          </div>
    </Card>,
    <Card className="card-portfolio">
      <img src={image2} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image3} className="portfolio-image" />
    </Card>,
  ];

  const items2 = [
    <Card className="card-portfolio">
      <img src={image4} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image5} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image6} className="portfolio-image" />
    </Card>
   
  ];

  const items3 = [
    <Card className="card-portfolio">
    <img src={image11} className="portfolio-image" />
  </Card>,
   <Card className="card-portfolio">
   <img src={image16} className="portfolio-image" />
 </Card>,
  <Card className="card-portfolio">
  <img src={image17} className="portfolio-image" />
</Card>,
    <Card className="card-portfolio">
      <img src={image7} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image8} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image9} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image10} className="portfolio-image" />
    </Card>
    
  ];

  const items4 = [
    <Card className="card-portfolio">
      <img src={image12} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image13} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image14} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
      <img src={image15} className="portfolio-image" />
    </Card>,
    <Card className="card-portfolio">
    <img src={image18} className="portfolio-image" />
  </Card>,
  <Card className="card-portfolio">
  <img src={image19} className="portfolio-image" />
</Card>,
<Card className="card-portfolio">
      <img src={image20} className="portfolio-image" />
    </Card>,
  ];

  const items5 = [
    <Card className="card-portfolio">
    <img src={image21} className="portfolio-image" />
  </Card>,
  <Card className="card-portfolio">
    <img src={image22} className="portfolio-image" />
  </Card>,
  <Card className="card-portfolio">
    <img src={image23} className="portfolio-image" />
  </Card>,
  <Card className="card-portfolio">
    <img src={image24} className="portfolio-image" />
  </Card>,
  ];
  const [items,setItems] = useState('1')


  const firstService = () => {
   setItems('1');

  };
  const secondService = () => {
    setItems('2');

  };
  const thirdService = () => {
    setItems('3');

  };
  const fourthService = () => {
    setItems('4');

  };
  const fifthService = () => {
    setItems('5');

  };

  return (
    <section id="portfolio-section">
      <Container>
        <div>
          <div className="mt-4">
            <h5 className="portfolio-subtitle">Recently Completed</h5>
          </div>
          <div>
            <h2 className="portfolio-titlee">Our Recent Projects</h2>
          </div>
          <div>
            <p className="portofolio-description">
            Discover the impressive portfolio of our construction company, showcasing our recent projects that highlight our commitment to quality, innovation, and client satisfaction.
            </p>
          </div>
        </div>
        <div>
          <div className="button-group">
            <button id="first-service" style={items==='1' ? stylingObject.button : styleObject.button } onClick={firstService}>Parket</button>
            <button id="second-service" style={items==='2' ? stylingObject.button : styleObject.button } onClick={secondService}>Málarar</button>
            <button id="third-service" style={items==='3' ? stylingObject.button : styleObject.button } onClick={thirdService}>Flisarar</button>
            <button id="fourth-service" style={items==='4' ? stylingObject.button : styleObject.button } onClick={fourthService}>Murárar</button>
            <button id="fifth-service" style={items==='5' ? stylingObject.button : styleObject.button } onClick={fifthService}>Gifsvinna</button>
          </div>
        </div>
      </Container>
      <Container>
      {items==='1' && 
        <AliceCarousel
        mouseTracking
        
        items={items1}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls={true}
      />
      }
      {items==='2' && 
        <AliceCarousel
        mouseTracking
        
        items={items2}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls={true}
      />
      }
      {items==='3' && 
        <AliceCarousel
        mouseTracking
        
        items={items3}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls={true}
      />
      }
      {items==='4' && 
        <AliceCarousel
        mouseTracking
        
        items={items4}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls={true}
      />
      }
      {items==='5' && 
        <AliceCarousel
        mouseTracking
        
        items={items5}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls={true}
      />
      }
        
      </Container>
    </section>
  );
};

export default Portfolio;
